import React from "react";
import { IRoomHeaderProps } from "./RoomHeaderProps";
import { Container, StyledImage } from "./RoomHeaderHelpers";
import { useDispatch, useSelector } from "react-redux";
import {
  removeRoom,
  updateRoom,
} from "../../../redux/slices/Checkout/checkout";
import defaultRoomImage from "../../../images/hotel_fallback.jpg";
import { StyledModal } from "../RoomCard/RoomCardHelpers";
import { Button, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { NewImageCarousel } from "../ImageCarousel/ImageCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDate, parseSearchDate } from "../../../services/dates";
import { setSearch } from "../../../redux/slices/Search/search";
import { navigate } from "gatsby";

const RoomHeader: React.FC<IRoomHeaderProps> = (props) => {
  const dispatch = useDispatch();
  const checkoutStore = useSelector((state: any) => state.checkout);
  const search = useSelector((state: any) => state.search);
  const room = checkoutStore.Rooms[props.roomIndex];
  const [showZoomedImage, setShowZoomedImage] = React.useState(false);
  const roomDetails = room && room.room;
  const handleChangeRoom = async (event: any) => {
    const roomId = event.target.dataset.roomid;
    let currentRoom = checkoutStore.Rooms[roomId];
    currentRoom = { ...currentRoom, rate: null, room: null, services: null };
    dispatch(updateRoom(currentRoom));
    navigate("/checkout");
  };
  const handleDeleteRoom = async (event: any) => {
    const roomId = event.target.dataset.roomid;
    const currentRoom = checkoutStore.Rooms[roomId];
    const newRooms = search.rooms.filter(
      (room: { id: string }) => room.id !== roomId
    );
    dispatch(setSearch({ ...search, rooms: newRooms }));
    dispatch(removeRoom(currentRoom));
  };
  if (roomDetails !== null) {
    const roomImage =
      roomDetails.imageUrls && roomDetails.imageUrls.length
        ? roomDetails.imageUrls[0]
        : defaultRoomImage;
    let guests = room.adults > 1 ? `${room.adults} Adults` : "1 Adult";
    const checkInDate = parseSearchDate(props.checkin);
    const checkOutDate = parseSearchDate(props.checkout);
    const formattedCheckIn = checkInDate
      ? formatDate(checkInDate, "MMM D")
      : "";
    const formattedCheckOut = checkOutDate
      ? formatDate(checkOutDate, "MMM D")
      : "";
    const stayDates = `${formattedCheckIn} - ${formattedCheckOut}`;
    const nights =
      room.rate.roomRateNightly.length > 1
        ? `${room.rate.roomRateNightly.length} Nights`
        : `1 Night`;
    if (room.children >= 1) {
      const children =
        room.children > 1 ? `${room.children} Children` : "1 Child";
      guests += `, ${children}`;
    }
    return (
      <>
        <Container>
          <Col className="room-image mb-0" style={{ position: "relative" }}>
            <StyledImage
              src={roomImage}
              alt={roomDetails.Name}
              id="roomImg"
              setShowZoomedImage={setShowZoomedImage}
              carouselImages={
                roomDetails?.galleryImages ? roomDetails?.galleryImages : null
              }
            />
          </Col>
          <Col>
            <div className="room-details py-3">
              <div className="mb-2 d-flex flex-row align-items-start">
                <FontAwesomeIcon icon="bed" className="me-2" />
                <div className="flex-grow-1 ">{roomDetails.name}</div>
              </div>
              <div
                className="mb-2 d-flex flex-row align-items-start"
                id="tripDates"
              >
                <FontAwesomeIcon
                  icon={["far", "calendar"]}
                  className="me-3 mt-1"
                />
                {stayDates} ({nights})
              </div>
              <div className="d-flex flex-row align-items-start">
                <FontAwesomeIcon icon="user-friends" className="me-2 mt-1" />
                {guests}
              </div>
              <div className="d-flex flex-row align-items-start mt-3 btn-container">
                {props.showRoomChangeLink && (
                  <Button
                    id={`room-change/edit-button`}
                    variant="link"
                    data-roomid={room.id}
                    onClick={handleChangeRoom}
                    className="pt-0"
                  >
                    Edit Room
                  </Button>
                )}
                {search.rooms.length > 1 ? (
                  <Button
                    id={`roomRemove`}
                    variant="link"
                    data-roomid={room.id}
                    onClick={handleDeleteRoom}
                    className="pt-0"
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            </div>
          </Col>
        </Container>
        <StyledModal
          show={showZoomedImage}
          onHide={() => setShowZoomedImage(false)}
          size="lg"
          centered
          aria-label="Room Gallery"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Room Gallery
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewImageCarousel
              activeImage={0}
              images={
                roomDetails?.galleryImages ? roomDetails?.galleryImages : null
              }
              isRoomCard={true}
            />
          </Modal.Body>
        </StyledModal>
      </>
    );
  } else {
    return <></>;
  }
};

export default RoomHeader;
